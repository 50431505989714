import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { useContext, useEffect, useState } from 'react';
import Button from '../../components/button';
import NavBar from '../../components/navbar';
import Page from '../../components/page';
import Row from '../../components/row';
import Table from '../../components/table';
import EventRow from '../../components/table/eventRow';
import NoticeRow from '../../components/table/noticeRow';
import OfferRow from '../../components/table/offerRow';
import UserRow from '../../components/table/userRow';
import Title from '../../components/title';
import { history } from '../../store';
import { colours, spacing } from '../../styles/theme';
import { EventContext } from '../../utils/eventContext';
import { LoadingContext } from '../../utils/loadingContext';
import { NoticeContext } from '../../utils/noticeContext';
import { OfferContext } from '../../utils/offerContext';
import { UserContext } from '../../utils/userContext';
import { Container, Half } from './style';
import { getHeaders } from './utils';

export const EVENTS_QUERY = gql`
  {
    events: getEventsAdmin {
      id
      startTime
      title
      guest
      first
      description
      second
      third
      guestQty
      firstReleaseQty
      secondReleaseQty
      thirdReleaseQty
      firstReleaseCents
      secondReleaseCents
      thirdReleaseCents
      draft
      free
      payout
      purchaseTimeLimit
      guestCheckInTimeLimit
      image
      sqip
      featured
      city
      clubID
    }
  }
`;

const OFFERS_QUERY = gql`
  {
    offers: getOffersAdmin {
      id
      title
      description
      terms
      image
      expirationDate
      startDate
      businessID
      available
      taken
      redeemed
      total
      location
      draft
      validUntil
      quantity
      couponCategoriesID
      category {
        id
        name
      }
      city
      featured
      type
      code
    }
  }
`;

const USERS_QUERY = gql`
  {
    users: getUsers {
      id
      name
      type
      email
      role
      businessID
    }
  }
`;

const NOTICES_QUERY = gql`
  {
    notices: getNotices {
      id
      title
      description
      active
      image
      action
    }
  }
`;

const headers = getHeaders();

const getQuery = (view: string) => {
  switch (view) {
    case 'events':
      return EVENTS_QUERY;
    case 'offers':
      return OFFERS_QUERY;
    case 'users':
      return USERS_QUERY;
    case 'notices':
      return NOTICES_QUERY;
  }
};

const setHeader = (view: string) => {
  switch (view) {
    case 'events':
      return headers.events;
    case 'offers':
      return headers.offers;
    case 'users':
      return headers.users;
    case 'notices':
      return headers.notices;
  }
};
const buttons = ['EVENTS', 'OFFERS', 'USERS', 'NOTICES'];
const Admin = () => {
  const eventCtx = useContext(EventContext);
  const tabContext = useContext(LoadingContext);
  const offerCtx = useContext(OfferContext);
  const [view, setView] = useState(tabContext!.tabSelected);
  const userCtx = useContext(UserContext);
  const noticeCtx = useContext(NoticeContext);
  const res = useQuery(getQuery(view)!, { fetchPolicy: 'no-cache' });
  const events = (res.data && res.data.events) || [];
  const offers = (res.data && res.data.offers) || [];
  const users = (res.data && res.data.users) || [];
  const notices = (res.data && res.data.notices) || [];

  const topButton = {
    backgroundColor: colours.white,
    marginLeft: `${spacing}px`,
    border: `1px solid ${colours.black}`,
  };
  const buttonSelected = {
    backgroundColor: colours.yellow,
    marginLeft: `${spacing}px`,
  };
  const createButton = {
    ...topButton,
    paddingLeft: 15,
    paddingRight: 15,
    border: `1px solid ${colours.black}`,
  };
  const getButton = (name: string) => {
    const selected = name.toLowerCase() === view;
    return (
      <Button
        key={name}
        colour={selected ? colours.green : undefined}
        text={name}
        style={selected ? buttonSelected : topButton}
        onClick={() => {
          tabContext!.setTabSelected(name.toLowerCase());
          setView(name.toLowerCase());
        }}
      />
    );
  };
  const getButtonCreate = (name: string, goTo: string) => {
    let pushNotifications = false;
    if (goTo === 'push-notifications') pushNotifications = true;
    const show = goTo.toLowerCase() === view;
    if (show || pushNotifications) {
      return (
        <Button
          text={name}
          style={createButton}
          onClick={() => {
            eventCtx!.setEvent(null);
            offerCtx!.setOffer(null);
            userCtx!.setUser(null);

            history.push(`/${goTo}`);
          }}
        />
      );
    }
    return null;
  };

  useEffect(() => {
    noticeCtx!.setNotice(null);
    eventCtx!.setEvent(null);
    offerCtx!.setOffer(null);
  }, []);
  return (
    <Page>
      <NavBar />
      <Container>
        <Row style={{ justifyContent: 'space-between', marginBottom: 5 }}>
          <Half>{buttons.map((b) => getButton(b))}</Half>
          <Half>
            {getButtonCreate('CREATE EVENT', 'events')}
            {getButtonCreate('CREATE OFFER', 'offers')}
            {getButtonCreate('CREATE USER', 'users')}
            {getButtonCreate('CREATE NOTICE', 'notices')}
          </Half>
        </Row>
        {/* <Row style={{ justifyContent: 'space-between', marginTop: 5 }}>
          <Half></Half>
          <Half>{getButtonCreate('Send Push', 'push-notifications')}</Half>
        </Row> */}
        <Title>{view[0].toUpperCase() + view.substring(1, view.length)}</Title>
        <Table
          headers={setHeader(view)!}
          renderRows={() => {
            if (view === 'events') {
              return events.map((e: any) => (
                <EventRow
                  key={e.id}
                  event={e}
                  headers={setHeader(view)!}
                  onClick={() => {
                    eventCtx!.setEvent(e);
                    history.push('/events');
                  }}
                />
              ));
            } else if (view === 'offers') {
              return offers.map((o: any) => (
                <OfferRow
                  key={o.id}
                  offer={o}
                  headers={setHeader(view)!}
                  onClick={() => {
                    offerCtx!.setOffer(o);
                    history.push('/offers');
                  }}
                />
              ));
            } else if (view === 'users') {
              return users.map((u: any) => (
                <UserRow
                  key={u.name + u.id}
                  user={u}
                  headers={setHeader(view)!}
                  onClick={() => {
                    userCtx!.setUser(u);
                    history.push('/users');
                  }}
                />
              ));
            } else if (view === 'notices') {
              return notices.map((n: any) => (
                <NoticeRow
                  key={n.id}
                  notice={n}
                  headers={setHeader(view)!}
                  onClick={() => {
                    noticeCtx!.setNotice(n);
                    history.push('/notices');
                  }}
                />
              ));
            }
          }}
        />
      </Container>
    </Page>
  );
};

export default Admin;
