import moment from 'moment';
import { PropsWithChildren } from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import { colours, fonts, spacing } from '../../styles/theme';
import { Col, RowWrapper, Status } from './style';
import { IHeader } from './table';
const Payout = styled.span<{ qty: number }>`
  color: ${(p) => (p.qty > 0 ? colours.green : colours.red)};
  font-family: ${fonts.bold};
`;

const Bold = styled.span`
  font-family: ${fonts.bold};
`;
const EventRow = (props: PropsWithChildren<{ event: any; headers: IHeader[]; onClick: any }>) => {
  const { event: e, headers, onClick } = props;
  return (
    <RowWrapper onClick={onClick}>
      <Col style={{ width: headers[0].width, justifyContent: 'flex-start', marginLeft: spacing }}>{e.id}</Col>
      <Col style={{ width: headers[1].width }}>
        <Status isDraft={e.draft} />
      </Col>
      <Col style={{ width: headers[2].width, justifyContent: 'flex-start', marginLeft: spacing }}>{e.title}</Col>

      {!isMobile && (
        <>
          <Col style={{ width: headers[3].width }}>
            <Bold>{e.guest}</Bold>/{e.guestQty}
          </Col>
          <Col style={{ width: headers[4].width }}>
            <Bold>{e.first}</Bold>/{e.firstReleaseQty}
          </Col>
          <Col style={{ width: headers[5].width }}>
            <Bold> {e.second}</Bold>/{e.secondReleaseQty}
          </Col>
          <Col style={{ width: headers[6].width }}>
            <Bold>{e.third}</Bold>/{e.thirdReleaseQty}
          </Col>
          <Col style={{ width: headers[7].width }}>
            <Bold>{e.first + e.second + e.third}</Bold> ({e.guest})
          </Col>
          <Col style={{ width: headers[8].width }}>
            <Payout qty={e.payout}>{e.free ? 'FREE' : `€${e.payout / 100}`}</Payout>
          </Col>
          <Col style={{ width: headers[9].width }}>{moment(e.startTime).format('dddd - DD.MM.YYYY')}</Col>
        </>
      )}

      {isMobile && (
        <>
          <Col style={{ width: headers[2].width }}>
            <Bold>{e.first + e.second + e.third}</Bold> ({e.guest})
          </Col>
          <Col style={{ width: headers[3].width }}>
            <Payout qty={e.payout}>€{e.payout / 100}</Payout>
          </Col>
          <Col style={{ width: headers[4].width, textAlign: 'center' }}>{moment(e.startTime).format('dddd - DD.MM.YYYY')}</Col>
        </>
      )}
    </RowWrapper>
  );
};

export default EventRow;
