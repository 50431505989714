import { isMobile } from 'react-device-detect';

const eHeaders = [
  { width: '4%', name: '' },
  { width: '5%', name: '' },
  { width: '25%', name: '' },
  { width: '8.5%', name: 'guest list' },
  { width: '8.5%', name: 'FIRST RELEASE' },
  { width: '10%', name: 'SECOND RELEASE' },
  { width: '8.5%', name: 'THIRD RELEASE' },
  { width: '8.5%', name: 'TOTAL' },
  { width: '8.5%', name: 'PAY OUT' },
  { width: '13.5%', name: 'DATE' },
];

const oHeaders = [
  { width: '4%', name: '' },
  { width: '5%', name: '' },
  { width: '25%', name: '' },
  { width: '13%', name: 'AVAILABLE' },
  { width: '13%', name: 'CLAIMED' },
  { width: '13%', name: 'REDEEMED' },
  { width: '13%', name: 'EXPIRES' },
  { width: '13%', name: 'LOCATION' },
];

const rHeaders = [
  { width: '5%', name: '' },
  { width: '35%', name: '' },
  { width: '20%', name: 'TITLE' },
  { width: '20%', name: 'PRICE' },
  { width: '20%', name: 'CATEGORY' },
];

const uHeaders = [
  { width: '5%', name: 'ID' },
  { width: '35%', name: 'EMAIL' },
  { width: '20%', name: 'NAME' },
  { width: '20%', name: 'TYPE' },
  { width: '20%', name: 'ROLE' },
];

const eMobileHeaders = [
  { width: '5%', name: '' },
  { width: '40%', name: '' },
  { width: '18.3%', name: 'TOTAL' },
  { width: '18.3%', name: 'PAY OUT' },
  { width: '18.3%', name: 'DATE' },
];

const oMobileHeaders = [
  { width: '5%', name: '' },
  { width: '30%', name: '' },
  { width: '16.5%', name: 'AVAILABLE' },
  { width: '16.5%', name: 'CLAIMED' },
  { width: '16.5%', name: 'REDEEMED' },
  { width: '16.5%', name: 'EXPIRES' },
];

const rMobileHeaders = [
  { width: '10%', name: '' },
  { width: '0%', name: '' },
  { width: '30%', name: 'TITLE' },
  { width: '30%', name: 'PRICE' },
  { width: '30%', name: 'CATEGORY' },
];

const noticesHeader = [
  { width: '5%', name: '' },
  { width: '45%', name: 'TITLE' },
  { width: '45%', name: 'DESCRIPTION' },
];

export const getHeaders = () => ({
  events: isMobile ? eMobileHeaders : eHeaders,
  offers: isMobile ? oMobileHeaders : oHeaders,
  users: uHeaders,
  notices: noticesHeader,
});
